angular.module(config.app.name).service('sessionStorageService', function () {

    const simpleTypes = new Set(['boolean', 'number', 'string']);

    var isSessionStorageAvailable = (function () {
        try {
            sessionStorage.setItem('test', 'test');
            sessionStorage.removeItem('test');
            return true;
        } catch (error) {
            return false;
        }
    })();
    if (!isSessionStorageAvailable) {
        console.error('Session storage is not available in this browser.');
    }
    // Public API for the service
    return {
        // Store a value in session storage
        set: function (key, value) {            
            if (isSessionStorageAvailable) {
                try {
                    if (simpleTypes.has(typeof value)) {
                        sessionStorage.setItem(key, value);
                    } else {
                        sessionStorage.setItem(key, JSON.stringify(value));
                    }
                } catch (error) {
                    console.error('Error storing data in sessionStorage:', error);
                }
            }
        },
        // Retrieve a value from session storage
        get: function (key) {
            if (isSessionStorageAvailable) {
                try {
                    var data = sessionStorage.getItem(key);
                    if (data) {
                        return JSON.parse(data);
                    }
                } catch (error) {
                    return data;
                }
                return null;
            }
        },
        // Remove a value from session storage
        remove: function (key) {
            if (isSessionStorageAvailable) {
                try {
                    sessionStorage.removeItem(key);
                } catch (error) {
                    console.error('Error removing data from sessionStorage:', error);
                }
            }
        },
        clear: function () {
            if (isSessionStorageAvailable) {
                try {
                    sessionStorage.clear();
                } catch (error) {
                    console.error('Error clearing session storage:', error);
                }
            }
        }
    };
});